.swiper-container {
    width: 100%;
    height: 80%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background:'transparent';
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: 'hidden';
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.swiper-pagination-bullet{
  background-color: #679c9e;
}
